.page-result {
  background-color: #e6d5d3;
}

.page-result .header .x-app-header__inner {
  background-color: #f19fa6;
}

.page-result .header .x-app-header__title {
  color: #f13b3b;
  font-weight: bold;
  font-size: 0.34rem;
}

.page-result .header .back-home {
  width: 0.5rem;
  height: 0.5rem;
  background: url("./imgs/icon-back.png") no-repeat 50%/0.5rem;
}

.page-result .header .rematch {
  color: #fff;
  background-color: #f13b3b;
}

.page-result .body {
  padding: 0;
  padding-bottom: 0.5rem;
}

.page-result .body .banner img {
  display: block;
  width: 100%;
}

.page-result .body .names {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
}

.page-result .body .names .man {
  width: 2.96rem;
  height: 4.3rem;
}

.page-result .body .names .man .name {
  width: 100%;
  padding: 0.3rem 0.1rem;
  color: #000;
  font-weight: bold;
  font-size: 0.32rem;
  text-align: center;
}

.page-result .body .names .man.male {
  background: url("./imgs/male.png") no-repeat 50%/cover;
}

.page-result .body .names .man.female {
  background: url("./imgs/female.png") no-repeat 50%/cover;
}

.page-result .body .names .heart {
  width: 0.98rem;
  height: 0.46rem;
  background: url("./imgs/icon-heart.png") no-repeat 50%/cover;
}

.page-result .body .price {
  color: #020202;
  font-weight: bold;
  font-size: 0.28rem;
  text-align: center;
}

.page-result .body .price em {
  color: #e93323;
  font-size: 0.5rem;
  font-style: normal;
}

.page-result .body .countdown {
  padding: 0.1rem 0;
  color: #000;
  font-size: 0.28rem;
  text-align: center;
}

.page-result .body .pay-button {
  padding: 0 0.4rem;
}

.page-result .body .pay-button .btn {
  margin: 0.2rem 0;
  font-weight: bold;
  font-size: 0.4rem;
}

.page-result .body .pay-button .btn .icon {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 0.1rem;
}

.page-result .body .pay-button .btn.wechat {
  background-color: #07c160;
}

.page-result .body .pay-button .btn.wechat .icon {
  background: url("./imgs/icon-wechat.png") no-repeat 50%/cover;
}

.page-result .body .pay-button .btn.alipay {
  background-color: #1296db;
}

.page-result .body .pay-button .btn.alipay .icon {
  background: url("./imgs/icon-alipay.png") no-repeat 50%/cover;
}

.page-result .body .tip {
  margin: 0.1rem 0.2rem;
  padding: 0.2rem;
  color: #333;
  font-size: 0.28rem;
  background-color: #e3dbda;
  border-radius: 0.1rem;
}

.page-result .body .tip em {
  color: #e56767;
  font-style: normal;
}

.page-result .body .pay-tip {
  margin: 0.4rem 0;
  color: #333;
  font-weight: bold;
  font-size: 0.34rem;
  line-height: 0.8rem;
  text-align: center;
  border-top: solid 0.01rem #d2ab8b;
  border-bottom: solid 0.01rem #d2ab8b;
}

.page-result .body .pay-tip em {
  color: #e56767;
  font-style: normal;
}

.page-result .body .result-list {
  padding-bottom: 0.4rem;
}

.page-result .ad {
  position: relative;
  display: block;
  padding: 0 0.4rem 0.4rem 0.4rem;
  overflow: hidden;
  border-radius: 0.05rem;
  animation: sharke 3s linear infinite;
}

.page-result .ad h1 {
  position: absolute;
  bottom: 0.6rem;
  left: 0;
  z-index: 9;
  width: 100%;
  color: #fff;
  text-align: center;
}

.page-result .ad img {
  display: block;
  width: 100%;
  border-radius: 0.05rem;
}

@keyframes sharke {
  0% {
    transform: scale(1);
  }

  15% {
    transform: scale(0.95);
  }

  30% {
    transform: scale(1.05);
  }

  45% {
    transform: scale(1);
  }

  to {
    transform: scale(1);
  }
}

.page-result .step.step1 {
  position: relative;
  height: calc(100vh - 1.1rem);
  padding: 1.2rem 0.4rem;
}

.page-result .step.step1 .tip {
  margin-top: -0.5rem;
  font-weight: bold;
  font-size: 0.34rem;
  text-align: center;
}

.page-result .step.step1 .qwrapper {
  position: relative;
  width: 100%;
  background: #f2c4bf;
  border-radius: 0.2rem;
}

.page-result .step.step1 .qwrapper .number-list {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0.2rem;
  color: #fff;
  font-size: 0.36rem;
  line-height: 0.8rem;
  background-color: #d45a5a;
  border-radius: 0.2rem 0 0.2rem 0;
}

.page-result .step.step1 .qwrapper .qcontent {
  width: 100%;
  padding: 0.5rem;
  color: #6377f2;
}

.page-result .step.step1 .qwrapper .qcontent .title {
  margin-bottom: 0.4rem;
  padding: 0.6rem 0;
  color: #d45a5a;
  font-weight: bold;
  font-size: 0.4rem;
  text-align: center;
}

.page-result .step.step1 .qwrapper .qcontent .answers {
  position: relative;
}

.page-result .step.step1 .qwrapper .qcontent .answers .item {
  min-height: 0.76rem;
  margin-bottom: 0.4rem;
  padding: 0.2rem;
  padding-left: 0.6rem;
  color: #666;
  font-weight: bold;
  font-size: 0.34rem;
  line-height: 0.44rem;
  background-color: #fff;
  border-radius: 0.4rem;
}

.page-result .step.step1 .qwrapper .qcontent .answers .item.checked {
  color: #fff;
  background-color: #f13b3b;
}

.page-result .step.step2 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6.7rem;
  height: 9rem;
  margin: 0 auto;
  margin-top: -4.5rem;
  margin-left: -3.35rem;
  padding: 0.6rem 0.8rem;
  background: url("./imgs/blur.png") no-repeat 50% 0/6.61rem 4.93rem rgba(255,255,255,0.7);
  border: 0.02rem solid #fff;
  border-radius: 0.28rem;
}

.page-result .step.step2 .t {
  margin-bottom: 0.9rem;
}

.page-result .step.step2 .t .title {
  color: #d45a5a;
  font-weight: bold;
  font-size: 0.62rem;
}

.page-result .step.step2 .t .subtitle {
  color: #d45a5a;
  font-size: 0.32rem;
}

.page-result .step.step2 .content {
  position: relative;
  width: 4.68rem;
  height: 4.68rem;
  margin: 0.4rem auto;
}

.page-result .step.step2 .content .circle {
  position: absolute;
  top: 0;
  left: 0;
  width: 4.68rem;
  height: 4.68rem;
  background: url("./imgs/circle.png") no-repeat 50%/cover;
  animation: circleAnimationn 3s ease-in-out infinite;
}

.page-result .step.step2 .content .secret {
  position: absolute;
  top: 1.12rem;
  left: 1.14rem;
  width: 2.4rem;
  height: 2.44rem;
  font-weight: bold;
  line-height: 2.44rem;
  text-align: center;
}

.page-result .step.step3 .score {
  margin-bottom: 0.4rem;
  font-weight: bold;
  font-size: 0.4rem;
  text-align: center;
}

.page-result .step.step3 .score em {
  color: #d45a5a;
  font-weight: bold;
  font-size: 1.2rem;
}

@keyframes circleAnimationn {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}