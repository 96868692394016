.com-page-pay-result-lock {
  margin-bottom: 0.2rem;
}

.com-page-pay-result-lock .title {
  position: relative;
  width: 100%;
  height: 0.76rem;
  padding: 0 0.4rem;
  color: #fff;
  font-size: 0.28rem;
  line-height: 0.76rem;
  background: url("./title-bg.png") no-repeat 50%/cover;
}

.com-page-pay-result-lock .title .arrow {
  position: absolute;
  top: 0.23rem;
  right: 0.3rem;
  width: 0.3rem;
  height: 0.3rem;
  background: url("./icon-arrow.png") no-repeat 50%/cover;
}

.com-page-pay-result-lock .content {
  display: flex;
  align-items: center;
  padding: 0.2rem 0.3rem;
}

.com-page-pay-result-lock .content .left {
  flex: 1;
  color: #333;
  font-size: 0.28rem;
}

.com-page-pay-result-lock .content .left p {
  margin: 0.1rem 0;
  line-height: 0.36rem;
}

.com-page-pay-result-lock .content .right {
  width: 1.12rem;
  height: 1.8rem;
  background: url("./lock.png") no-repeat 50%/cover;
}