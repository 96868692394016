.page-index {
  overflow: hidden;
  background-color: #fcf4f3;
}

.page-index .header .x-app-header__inner {
  background-color: #f19fa6;
}

.page-index .header .x-app-header__title {
  color: #f13b3b;
  font-weight: bold;
  font-size: 0.34rem;
}

.page-index .header .order {
  color: #fff;
  background-color: #f13b3b;
}

.page-index .x-app-body {
  padding: 0;
}

.page-index .banner {
  position: relative;
  overflow: hidden;
}

.page-index .banner .img {
  display: block;
  width: 100%;
}

.page-index .banner .marquee {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.6rem;
  font-size: 0.3rem;
  line-height: 0.6rem;
  background-color: #f13b3b;
}

.page-index .banner .marquee .inner {
  color: #fff;
  word-wrap: normal;
  word-break: keep-all;
  animation: marquee 30s linear infinite;
}

.page-index .form {
  position: relative;
  padding: 0.4rem;
}

.page-index .form .input-wrapper {
  margin-bottom: 0.2rem;
}

.page-index .form .input-wrapper .item {
  display: flex;
  margin-bottom: 0.2rem;
}

.page-index .form .input-wrapper .item .icon {
  flex-shrink: 0;
  width: 0.6rem;
  height: 0.8rem;
  border-radius: 0.08rem 0 0 0.08rem;
}

.page-index .form .input-wrapper .item .label {
  flex-shrink: 0;
  padding: 0 0.2rem 0 0.1rem;
  color: #fff;
  font-size: 0.28rem;
  line-height: 0.78rem;
  background-color: #f8a29b;
}

.page-index .form .input-wrapper .item .input {
  display: flex;
  flex: 1;
  padding-left: 0.2rem;
  overflow: hidden;
  background-color: #fff;
  border-top: solid 0.01rem #d2ab8b;
  border-right: solid 0.01rem #d2ab8b;
  border-bottom: solid 0.01rem #d2ab8b;
  border-radius: 0 0.08rem 0.08rem 0;
}

.page-index .form .input-wrapper .item .input>input {
  display: block;
  flex: 1;
  width: 100%;
  height: 0.78rem;
  font-size: 0.3rem;
  border: none;
}

.page-index .form .input-wrapper .item.male .icon {
  background: url("./imgs/icon-male.png") no-repeat 50%/0.5rem #f8a29b;
}

.page-index .form .input-wrapper .item.female .icon {
  background: url("./imgs/icon-female.png") no-repeat 50%/0.5rem #f8a29b;
}

.page-index .form .input-wrapper .label {
  font-size: 0.28rem;
}

.page-index .form .agreement {
  display: flex;
  align-items: flex-start;
  font-size: 0.26rem;
}

.page-index .form .agreement .text {
  padding: 0.1rem 0;
  color: #e56767;
  line-height: 0.32rem;
}

.page-index .form .agreement .text a {
  color: #f15424;
  text-decoration: underline;
}

.page-index .form .agreement .checkbox.x-radio--checked .x-radio__icon {
  background: #d45a5a;
}

.page-index .footer {
  padding: 0.4rem;
  background-color: #fcf4f3;
}

.page-index .footer .btns .submit {
  background-color: #d45a5a;
}

.page-index .footer .btns .tip {
  padding: 0.1rem 0;
  color: #e56767;
  text-align: center;
}

.page-index .footer .btns .tip em {
  color: #e56767;
  font-weight: bold;
  font-style: normal;
}

.page-index .footer .company {
  color: #e56767;
  font-size: 0.2rem;
  text-align: center;
}

.page-index .footer .tip-warning {
  color: #b1afaf;
  font-size: 0.2rem;
  text-align: center;
}

@keyframes marquee {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}