.page-pay .header .x-app-header__inner {
  background-color: #f19fa6;
}

.page-pay .header .x-app-header__title {
  color: #f13b3b;
  font-weight: bold;
  font-size: 0.34rem;
}

.page-pay .header .back-home {
  width: 0.5rem;
  height: 0.5rem;
  background: url("./imgs/icon-back.png") no-repeat 50%/0.5rem;
}

.page-pay .body {
  padding: 0;
}

.page-pay .body .banner img {
  display: block;
  width: 100%;
}

.page-pay .body .names {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.2rem;
}

.page-pay .body .names .man {
  width: 2.96rem;
  height: 4.3rem;
}

.page-pay .body .names .man .name {
  width: 100%;
  padding: 0.3rem 0.1rem;
  color: #000;
  font-weight: bold;
  font-size: 0.32rem;
  text-align: center;
}

.page-pay .body .names .man.male {
  background: url("./imgs/male.png") no-repeat 50%/cover;
}

.page-pay .body .names .man.female {
  background: url("./imgs/female.png") no-repeat 50%/cover;
}

.page-pay .body .names .heart {
  width: 0.98rem;
  height: 0.46rem;
  background: url("./imgs/icon-heart.png") no-repeat 50%/cover;
}

.page-pay .body .price {
  color: #020202;
  font-weight: bold;
  font-size: 0.28rem;
  text-align: center;
}

.page-pay .body .price em {
  color: #e93323;
  font-size: 0.5rem;
  font-style: normal;
}

.page-pay .body .countdown {
  padding: 0.1rem 0;
  color: #000;
  font-size: 0.28rem;
  text-align: center;
}

.page-pay .body .pay-button {
  padding: 0 0.4rem;
}

.page-pay .body .pay-button .btn {
  margin: 0.2rem 0;
  font-weight: bold;
  font-size: 0.4rem;
}

.page-pay .body .pay-button .btn .icon {
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 0.1rem;
}

.page-pay .body .pay-button .btn.wechat {
  background-color: #07c160;
}

.page-pay .body .pay-button .btn.wechat .icon {
  background: url("./imgs/icon-wechat.png") no-repeat 50%/cover;
}

.page-pay .body .pay-button .btn.alipay {
  background-color: #1296db;
}

.page-pay .body .pay-button .btn.alipay .icon {
  background: url("./imgs/icon-alipay.png") no-repeat 50%/cover;
}

.page-pay .body .tip {
  margin: 0.1rem 0.2rem;
  padding: 0.2rem;
  color: #333;
  font-size: 0.28rem;
  background-color: #e3dbda;
  border-radius: 0.1rem;
}

.page-pay .body .tip em {
  color: #e56767;
  font-style: normal;
}

.page-pay .body .pay-tip {
  margin: 0.4rem 0;
  color: #333;
  line-height: 0.8rem;
  text-align: center;
  border-top: solid 0.01rem #d2ab8b;
  border-bottom: solid 0.01rem #d2ab8b;
}

.page-pay .body .result-list {
  padding-bottom: 0.4rem;
}